const changeColor = (color, amount) => {
	// #FFF not supported rather use #FFFFFF
	const clamp = (val) => Math.min(Math.max(val, 0), 0xff);
	const fill = (str) => ('00' + str).slice(-2);

	const num = parseInt(color.substr(1), 16);
	const red = clamp((num >> 16) + amount);
	const green = clamp(((num >> 8) & 0x00ff) + amount);
	const blue = clamp((num & 0x0000ff) + amount);
	return '#' + fill(red.toString(16)) + fill(green.toString(16)) + fill(blue.toString(16));
};

/**
 *
 * @param hex
 * @param alpha
 */
function hexToRgbA(hex, alpha) {
	var c;
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split('');
		if (c.length == 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}
		c = '0x' + c.join('');
		return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + alpha + ')';
	}
	return hex;
}

/**
 *
 * @param color
 */
function getModeFromColor(color) {
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)) {
		var c = color.substring(1); // strip #
		var rgb = parseInt(c, 16); // convert rrggbb to decimal
		var r = (rgb >> 16) & 0xff; // extract red
		var g = (rgb >> 8) & 0xff; // extract green
		var b = (rgb >> 0) & 0xff; // extract blue

		var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

		return luma < 128 ? 'dark' : 'light';
	}
	return 'regular';
}

export { changeColor, hexToRgbA, getModeFromColor };
